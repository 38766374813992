<template>
  <nuxt-link
    ref="el"
    :to="to"
    :class="['product-image-container']"
    :aria-label="product.currentTranslation?.title"
  >
    <button
      v-if="isHover && images.length > 1"
      class="absolute left-1 top-1/2 z-10 -translate-y-1/2"
      :aria-label="$t('previousSlide')"
      @click.stop.prevent="prevImage"
    >
      <UiIcon name="chevron_left" class="h-6 w-6 text-gray-elements" />
    </button>

    <UiLazyImg
      v-if="isEmpty(images)"
      :alt="product.currentTranslation?.title"
      class="transition-300 absolute inset-0 h-full w-full"
      src="/images/no-image.webp"
      data-e2e-image="product"
    />
    <template v-for="(image, idx) in images" v-else :key="idx">
      <UiLazyImg
        :alt="product.currentTranslation?.title"
        class="transition-300 absolute inset-0 h-full w-full"
        :src="image.url"
        :class="activeIdx === idx ? 'opacity-100' : 'opacity-0'"
        data-e2e-image="product"
      />
    </template>
    <button
      v-if="isHover && images.length > 1"
      class="absolute right-1 top-1/2 z-10 -translate-y-1/2"
      :aria-label="$t('nextSlide')"
      @click.stop.prevent="nextImage"
    >
      <UiIcon name="chevron_right" class="h-6 w-6 text-gray-elements" />
    </button>
  </nuxt-link>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router";
import { CatalogData, MediaFile } from "ecom-types";

const props = defineProps<{
  to: RouteLocationRaw;
  images: MediaFile[];
  isHover?: boolean;
  product: CatalogData;
}>();
const el = ref<HTMLElement | null>(null);

const activeIdx = ref(0);

watchEffect(() => {
  if (props.isHover) {
    setActiveIndex(1);
  } else {
    setActiveIndex(0);
  }
});

// function onMouseEnter() {
//   setActiveIndex(1);
// }
// function onMouseLeave() {
//   setActiveIndex(0);
// }
function setActiveIndex(idx: number) {
  if (idx > props.images.length - 1) return;
  activeIdx.value = idx;
}

function nextImage() {
  setActiveIndex(
    activeIdx.value >= props.images.length - 1 ? 0 : activeIdx.value + 1,
  );
}
function prevImage() {
  setActiveIndex(
    activeIdx.value <= 0 ? props.images.length - 1 : activeIdx.value - 1,
  );
}
</script>
